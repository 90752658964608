/* eslint-disable no-param-reassign */
// @flow
import { Box, Grid, Paper } from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import withWidth from '@material-ui/core/withWidth';
import { makeStyles } from '@material-ui/styles';
import { Button, FormField, FieldHint, Text, Divider, Select, SelectItem, FieldError } from '@shiftfinancial/design-system';
import clsx from 'clsx';
import { Field, Form, withFormik } from 'formik';
import type { FormikBag, FormikErrors } from 'formik';
import { TextField } from 'formik-material-ui';
import type { History, Location } from 'history';
import moment from 'moment';
import { withSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router';

import { useParams } from 'react-router-dom';

import { useAppDispatch } from 'src/store';

import actions from './actions';
import { DisbursalMethods } from './constants';
import SupplierSearch from './SupplierSearch';
import { formSchema } from './validations';
import responseCode from '../../../api/generated/responseCode';
import { type EntitySuggestionItem } from '../../../components/AbnAutoSuggest';
import AlertError from '../../../components/AlertError';
import AlertInfo from '../../../components/AlertInfo';
import BasicPageLayout from '../../../components/BasicPageLayout';
import { DatePicker } from '../../../components/DatePicker';
import DollarsAndCentsText from '../../../components/DollarsAndCentsText';
import { FormikDndAttachFiles, FormikNumberFormatInputField } from '../../../components/formik';
import FormItem from '../../../components/FormItem';
import initialDataSelectors from '../../../components/InitialData/selectors';
import { ATTACHMENT_SUPPORTED_FORMATS } from '../../../constants';
import { defaultHandleError, getErrorMessage } from '../../../lib/apiHelpers';
import { addDays, disableWeekends } from '../../../lib/dateUtils';
import { isMobileResolution } from '../../../lib/materialUiUtils';
import startupConfig, { type AttachmentConfig } from '../../../lib/startupConfig';
import { type Account } from '../../../types';
import type { Invoice } from '../Review/types';
import { NowDisbursalMethod, LaterDisbursalMethod } from './constants';

const { v4: uuidv4 } = require('uuid');

const useStyles = makeStyles((theme) => ({
  supplierInfo: {
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    [theme.breakpoints.up('xs')]: {
      padding: theme.spacing(2),
    },
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(4, 5),
    },
  },
  supplierInfoSelected: {
    backgroundColor: theme.palette.common.paleOrange,
  },
  fields: {
    borderTop: `1px solid ${theme.palette.grey['300']}`,
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(2),
    },
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(4, 5),
    },
    '& label': {
      textWrap: 'nowrap',
    },
  },
  invoiceAmountSubContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: theme.spacing(0.5),
    textWrap: 'nowrap',
    color: `${theme.palette.grey['500']}`,
  },
  invoiceDescriptionSubText: {
    color: theme.palette.grey.light,
    marginTop: theme.spacing(0.5),
  },
  footerButtons: {
    marginTop: theme.spacing(2),
  },
  cancelButton: {
    fontWeight: 'normal',
    [theme.breakpoints.up('xs')]: {
      width: '50%',
      marginRight: theme.spacing(0.5),
    },
    [theme.breakpoints.up('sm')]: {
      width: 90,
      marginRight: theme.spacing(1),
    },
  },
  submitButton: {
    minWidth: '10px',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  row: {
    margin: theme.spacing(0.5),
  },
  invoiceSection: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(1),
    paddingBottom: theme.spacing(2),
  },
  datepickerContainer: {
    width: '100%',
  },
  gridContainer: {
    maxWidth: '800px',
  },
  gridItem: {
    minWidth: '210px',
  },
  SelectField: {
    '& button': {
      minHeight: '48px',
    },
    '& button:enabled': {
      boxShadow: `0 0 0 0 ${theme.palette.grey['300']}`,
    },
  },
  FieldError: {
    color: theme.palette.error.main,
  },
}));

type FormValues = {
  availableBalance: number,
  invoiceAmount: string,
  invoiceFiles: Array<any>,
  invoiceDescription: string,
  invoiceNumber: string,
  invoicePrn: string,
  activeGcAccountId: string,
  supplierGcAccountId: string,
  supplierAccountName: string,
  supplierBsb: string,
  supplierBankAccountNumber: string,
  supplierContactId?: string,
  fileGroupId: string,
  supplierAbn: string,
  isNewSupplier: boolean,
  isAto: boolean,
  isDirector: boolean,
  disbursalMethod: string,
  disburseDate: string,
};

type Props = {
  width: string,
  invoice: Invoice,
  getSuppliers: (gcAccountId: string) => Promise<any>,
  getAccountBalance: (gcAccountId: string) => Promise<any>,
  addInvoice: (
    invoiceAmount: number,
    invoiceNumber: string,
    invoiceFiles: Array<any>,
    invoiceDescription: string,
    activeGcAccountId: ?string,
    supplierGcAccountId: ?string,
    supplierAbnOrAcn: string,
    supplierAccountName: string,
    supplierBsb: string,
    supplierBankAccountNumber: string,
    fileGroupId: string,
    created: Date,
    isNewSupplier: boolean,
    isDirector: boolean,
    disbursalMethod: string,
    disburseDate: ?Date
  ) => Promise<any>,
  searchDelay?: number,
  checkValidBsb: (bsbNumber: string) => Promise<any>,
  activeAccount: Account,
  history: History<Location>,
};

type WithHOCProps = {
  ...Props,
  isSubmitting: boolean,
  values: FormValues,
  errors: FormikErrors<FormValues>,
  touched: any,
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void,
  setFieldError: (fieldName: string, error: string) => void,
  deleteFiles: (gcAccountId: string, fileGroupId: string, files: Array<any>) => boolean | Promise<any>,
  deleteInvoiceFile: (gcAccountId: string, fileGroupId: string, fileName: string) => Promise<any>,
  uploadFile: (gcAccountId: string, fileGroupId: string, fileName: string) => Promise<any>,
};

const AddInvoice = (props: WithHOCProps) => {
  const {
    width,
    activeAccount,
    isSubmitting,
    values,
    getAccountBalance,
    errors,
    touched,
    setFieldValue,
    searchDelay = 1000,
    setFieldError,
    history,
    deleteFiles,
    uploadFile,
    deleteInvoiceFile,
  } = props;
  const classes = useStyles();
  const [accountBalance, setAccountBalanceState] = useState(0);
  const isMobile = isMobileResolution(width);
  const fromReview = history.location.state && (history.location.state: any).fromReview;
  const [nonSupportedBusinessMessage, setNonSupportedBusinessMessage] = useState('');
  // MUI V4 datepicker triggers an initial onChange when calendar opens, setting the value to min value.
  // below change is to prevent this behaviour by ignoring the first onChange, once we move away from MUI V4 this code can be cleaned up
  const [ignoreFirstDateSelect, setIgnoreFirstDateSelect] = useState(true);
  const dispatch = useAppDispatch();
  const { supplierGcAccountId } = useParams<{ supplierGcAccountId: string }>();
  const activeGcAccountId = useSelector(initialDataSelectors.selectSelectedGcAccountId) ?? '';

  useEffect(() => {
    setFieldValue('activeGcAccountId', activeGcAccountId);
    if (!supplierGcAccountId) {
      return;
    }

    dispatch(actions.getSupplier(activeGcAccountId, supplierGcAccountId)).then((response) => {
      const { payload, error } = response;

      if (!error && payload.data.suppliers.length > 0) {
        const selectedSupplier = payload.data.suppliers[0];
        setFieldValue('supplierGcAccountId', selectedSupplier.gcAccountId);
        setFieldValue('supplierAbn', selectedSupplier.abn);
        setFieldValue('supplierAccountName', selectedSupplier.accountName);
        setFieldValue('isNewSupplier', false);
        setFieldValue('isAto', selectedSupplier.isAto);
      }
    });

    dispatch(actions.getSupplierBankAccount(activeGcAccountId, supplierGcAccountId)).then((response) => {
      const { payload, error } = response;

      if (!error) {
        setFieldValue('supplierBsb', payload.data.bsb);
        setFieldValue('supplierBankAccountNumber', payload.data.accountNumber);
      }
    });
  }, [supplierGcAccountId]);

  useEffect(() => {
    if (!accountBalance && activeAccount) {
      getAccountBalance(activeAccount.gcAccountId).then((accountResponse) => {
        const { error, payload } = accountResponse;
        if (!error) {
          setAccountBalanceState(payload.data.availableBalance);
          setFieldValue('availableBalance', payload.data.availableBalance);
        }
      });
    }
    if (activeAccount) {
      const isDirector = activeAccount && activeAccount.roles.some((i) => i === 'Director');
      setFieldValue('isDirector', isDirector);
    }
  }, [getAccountBalance, accountBalance, activeAccount]);

  const handleNewSupplierSelected = (entity: EntitySuggestionItem, relatedEntity: ?EntitySuggestionItem, validationResponse: any) => {
    if (
      validationResponse.error &&
      validationResponse.payload &&
      validationResponse.payload.response &&
      validationResponse.payload.response.status === 400 &&
      validationResponse.payload.response.data &&
      validationResponse.payload.response.data.code === responseCode.AccountNonSupportedBusiness
    ) {
      setNonSupportedBusinessMessage(validationResponse.payload.response.data.detail);
    } else if (validationResponse.error) {
      setFieldError('supplierGcAccountId', getErrorMessage(validationResponse));
    } else {
      const validationResult = validationResponse.payload.data;

      setFieldValue('isNewSupplier', true);
      setFieldValue('supplierGcAccountId', validationResult.gcAccountId || '');
      setFieldValue('supplierAbn', entity.id);
      setFieldValue('supplierAccountName', entity.entityName);
      setFieldValue('isAto', entity.isAto);
    }
  };

  const handleCancel = () => {
    deleteFiles(activeAccount.gcAccountId, values.fileGroupId, values.invoiceFiles);
    history.goBack();
  };

  const { maxFilesCount, maxFileSizeInMb } = startupConfig.get().invoiceAttachment;

  const handleDisbursalMethodChange = (value: string) => {
    setFieldValue('disbursalMethod', value);
    setFieldValue('disburseDate', null);
    setIgnoreFirstDateSelect(true);
  };
  const handleDisbursementDateChange = (date: moment) => {
    if (!ignoreFirstDateSelect) {
      setFieldValue('disburseDate', date);
    }
    setIgnoreFirstDateSelect(false);
  };

  const minDate = addDays(moment(), 1);
  const maxDate = addDays(moment(), 61);

  return (
    <BasicPageLayout title='Make a payment' data-testid='uia-make-a-payment-page' noMargin={!isMobile}>
      <Form noValidate autoComplete='off'>
        <Paper>
          <Box
            className={clsx(classes.supplierInfo, {
              [classes.supplierInfoSelected]: values.supplierAbn,
            })}
          >
            <SupplierSearch
              searchDelay={searchDelay}
              defaultSelectedAbn={fromReview ? values.supplierAbn : ''}
              isAto={values.isAto}
              onNewSupplierSelected={handleNewSupplierSelected}
              onExistingSupplierSelected={() => {}}
            />
          </Box>
          {nonSupportedBusinessMessage ? (
            <Box className={classes.fields}>
              <AlertInfo data-testid='uia-nonSupportedBusinessMessage' message={nonSupportedBusinessMessage} />
            </Box>
          ) : null}
          {errors.supplierGcAccountId ? (
            <Box className={classes.fields}>
              <AlertError message={errors.supplierGcAccountId} />
            </Box>
          ) : null}
          {!errors.supplierGcAccountId && !nonSupportedBusinessMessage && values.supplierAbn ? (
            <Box className={classes.fields}>
              <Grid container spacing={2} className={classes.gridContainer}>
                <>
                  <Grid item xs={12}>
                    <Text fontFamily='standard' fontSize='large' fontWeight='medium'>
                      Payment Details
                    </Text>
                  </Grid>
                  <Grid item xs={12} sm={3} className={classes.gridItem}>
                    <FormItem label='BSB'>
                      <Field
                        component={FormikNumberFormatInputField}
                        variant='outlined'
                        fullWidth
                        id='supplierBsb'
                        name='supplierBsb'
                        data-testid='uia-supplierBsb'
                        value={values.supplierBsb}
                        pattern='[0-9]{6}'
                        format='######'
                        disabled={values.isAto}
                      />
                    </FormItem>
                  </Grid>
                  <Grid item xs={12} sm={3} className={classes.gridItem}>
                    <FormItem label='Account number'>
                      <Field
                        component={TextField}
                        variant='outlined'
                        fullWidth
                        id='supplierBankAccountNumber'
                        name='supplierBankAccountNumber'
                        data-testid='uia-supplierBankAccountNumber'
                        value={values.supplierBankAccountNumber}
                        InputProps={{
                          onChange: ({ target }) => {
                            setFieldValue('supplierBankAccountNumber', target.value.toString().replace(/ /g, ''), true);
                          },
                          spellcheck: 'false',
                        }}
                        disabled={values.isAto}
                      />
                    </FormItem>
                  </Grid>
                  <Divider />
                </>
              </Grid>
              <Grid item xs={12} className={classes.invoiceSection}>
                <Text fontFamily='standard' fontSize='large' fontWeight='medium'>
                  Invoice details
                </Text>
              </Grid>
              <Grid container direction='column' spacing={2} className={classes.gridContainer}>
                <Grid item xs={12} sm={3} className={classes.gridItem}>
                  <FormField
                    required={false}
                    label='Invoice amount'
                    hint={
                      <div className={classes.invoiceAmountSubContainer}>
                        <Text fontFamily='standard' fontSize='standard'>
                          Available balance: <DollarsAndCentsText amount={accountBalance} />
                        </Text>
                      </div>
                    }
                    fieldId='invoiceAmount'
                  >
                    {({ fieldId }) => (
                      <Field
                        component={FormikNumberFormatInputField}
                        variant='outlined'
                        fullWidth
                        id={fieldId}
                        name='invoiceAmount'
                        data-testid='uia-invoiceAmount'
                        value={values.invoiceAmount}
                        thousandSeparator
                        allowNegative={false}
                        startAdornment={<InputAdornment position='start'>$</InputAdornment>}
                        isAllowed={(x) => x.value !== 0}
                      />
                    )}
                  </FormField>
                </Grid>
              </Grid>
              <Grid container direction='row' spacing={2} className={classes.gridContainer}>
                <Grid item xs={12} sm={3} className={classes.gridItem}>
                  <FormField
                    required={false}
                    label='Pay supplier'
                    fieldId='disbursalMethod'
                    className={classes.SelectField}
                    data-testid='uia-disbursalMethod' 
                  >
                    <Select className={classes.SelectField} 
                        value={values.disbursalMethod} onValueChange={handleDisbursalMethodChange}
                        data-testid='uia-disbursalMethodButton' >
                      {DisbursalMethods.map((method, i) => {
                        return (
                          <SelectItem key={i} value={method.value}>
                            {method.label}
                          </SelectItem>
                        );
                      })}
                    </Select>
                    {errors.disbursalMethod ? <FieldError id='disbursalMethodError'>{errors.disbursalMethod}</FieldError> : null}
                  </FormField>
                </Grid>
                {values.disbursalMethod === LaterDisbursalMethod.value && (
                  <Grid item xs={12} sm={3} className={classes.gridItem}>
                    <FormField required={false} label='Date' fieldId='disburseDate'>
                      <DatePicker
                        selectedDate={values.disburseDate ?? null}
                        minDate={minDate}
                        maxDate={maxDate}
                        shouldDisableDate={disableWeekends}
                        className={classes.datepickerContainer}
                        onDateChange={handleDisbursementDateChange}
                      />
                    {errors.disburseDate ? <FieldError id='disburseDateError'>{errors.disburseDate}</FieldError> : null}
                    </FormField>
                  </Grid>
                )}
              </Grid>
              <Grid container direction='column' spacing={2} className={classes.gridContainer}>
                {!props.values.isAto ? (
                  <Grid item xs={12} sm={3} className={classes.gridItem}>
                    <FormField label='Invoice reference' fieldId='invoiceNumber'>
                      {({ fieldId }) => (
                        <Field
                          component={TextField}
                          variant='outlined'
                          fullWidth
                          id={fieldId}
                          name={fieldId}
                          data-testid='uia-invoiceNumber'
                          value={values.invoiceNumber}
                          inputProps={{
                            maxLength: 18,
                          }}
                        />
                      )}
                    </FormField>
                  </Grid>
                ) : (
                  <Grid item xs={12} sm={3} className={classes.gridItem}>
                    <FormField
                      required
                      label='Payment Reference Number (PRN)'
                      hint={<FieldHint id='static-id'>This will appear on your supplier's bank statement.</FieldHint>}
                      fieldId='invoicePrn'
                    >
                      {({ fieldId }) => (
                        <Field
                          component={TextField}
                          variant='outlined'
                          fullWidth
                          id={fieldId}
                          name={fieldId}
                          data-testid='uia-invoicePrn'
                          value={values.invoicePrn}
                          inputProps={{
                            maxLength: 100,
                          }}
                          onChangeText={() => {
                            setFieldValue('invoiceNumber', values.invoicePrn);
                          }}
                        />
                      )}
                    </FormField>
                  </Grid>
                )}
              </Grid>
              <Grid container direction='column' spacing={2}>
                <Grid item xs={12} sm={12}>
                  <FormField label={'Invoice'} fieldId='invoiceFiles'>
                    {({ fieldId }) => (
                      <FormikDndAttachFiles
                        name={fieldId}
                        values={values}
                        errors={errors}
                        touched={touched}
                        accept={ATTACHMENT_SUPPORTED_FORMATS.toString()}
                        gcAccountId={activeAccount.gcAccountId}
                        fileGroupId={values.fileGroupId}
                        maxFilesCount={maxFilesCount}
                        maxFileSizeInMb={maxFileSizeInMb}
                        onFileUpload={uploadFile}
                        onFileDelete={deleteInvoiceFile}
                      />
                    )}
                  </FormField>
                </Grid>
              </Grid>
            </Box>
          ) : null}
        </Paper>
        {!errors.supplierGcAccountId && !nonSupportedBusinessMessage && values.supplierAbn ? (
          <Box display='flex' justifyContent={isMobile ? 'inherit' : 'space-between'} className={classes.footerButtons}>
            <Button className={classes.submitButton} appearance='primary' type='submit' disabled={isSubmitting} data-testid='uia-reviewButton'>
              Continue
            </Button>
            <Button
              className={classes.cancelButton}
              appearance='neutral'
              disabled={isSubmitting}
              onClick={handleCancel}
              data-testid='uia-cancelButton'
            >
              Cancel
            </Button>
          </Box>
        ) : null}
      </Form>
    </BasicPageLayout>
  );
};

type WrapperProps = {
  ...Props,
  fileGroupIdForTesting: string,
  invoiceAttachmentConfig: AttachmentConfig,
  enqueueSnackbar: (snack: string, options?: Object) => void,
};

export default withRouter<WrapperProps>(
  withSnackbar(
    withFormik({
      mapPropsToValues: ({ fileGroupIdForTesting, invoice, history }): FormValues => {
        const fromReview = history.location.state && (history.location.state: any).fromReview;

        return {
          invoiceAmount: fromReview && invoice.amount !== 0 ? String(invoice.amount) : '',
          invoiceFiles: fromReview && invoice.attachments ? invoice.attachments : [],
          invoiceNumber: fromReview ? invoice.invoiceNumber : '',
          invoiceDescription: fromReview ? String(invoice.invoiceDescription) : '',
          supplierGcAccountId: fromReview && invoice.supplierGcAccountId ? invoice.supplierGcAccountId : '',
          supplierAccountName: fromReview ? invoice.supplierAccountName : '',
          supplierBsb: fromReview && invoice.supplierBsb !== 0 ? String(invoice.supplierBsb) : '',
          supplierBankAccountNumber: fromReview ? invoice.supplierBankAccountNumber : '',
          supplierContactId: fromReview ? invoice.supplierContactId : '',
          fileGroupId: fromReview ? invoice.fileGroupId : fileGroupIdForTesting || uuidv4(),
          supplierAbn: fromReview ? invoice.supplierAbn : '',
          isNewSupplier: invoice.isNewSupplier,
          invoicePrn: invoice.invoicePrn,
          isAto: false,
          isDirector: fromReview ? invoice.isDirector : false,
          disbursalMethod: fromReview ? invoice.disbursalMethod : null,
          disburseDate: fromReview ? invoice.disburseDate : null,
        };
      },
      validateOnBlur: false,
      validationSchema: formSchema,
      handleSubmit: (values: FormValues, formikBag: FormikBag<WrapperProps, FormValues>) => {
        const { props } = formikBag;
        const {
          availableBalance,
          invoiceAmount,
          invoiceNumber,
          invoiceFiles,
          invoiceDescription,
          activeGcAccountId,
          supplierGcAccountId,
          supplierAbn,
          supplierAccountName,
          supplierBsb,
          supplierBankAccountNumber,
          fileGroupId,
          isNewSupplier,
          invoicePrn,
          isDirector,
          disbursalMethod,
          disburseDate,
        } = values;

        // special validation
        let error = false;
        if (!disbursalMethod) {
          formikBag.setFieldError('disbursalMethod', 'Please select a value');
          error = true;
        }
        if (disbursalMethod === LaterDisbursalMethod.value && !disburseDate) {
          formikBag.setFieldError('disburseDate', 'Please select a date');
          error = true;
        }
        if (disbursalMethod === NowDisbursalMethod.value && invoiceAmount > availableBalance)
        {
          formikBag.setFieldError('invoiceAmount', 'Please enter an invoice amount lower than your available funds');
          error = true;
        }
        if (error) {
          formikBag.setSubmitting(false);
          return;
        }
        props
          .addInvoice(
            Number(invoiceAmount),
            values.isAto ? invoicePrn : invoiceNumber,
            invoiceFiles,
            values.isAto ? invoicePrn : invoiceDescription,
            activeGcAccountId,
            supplierGcAccountId,
            supplierAbn,
            supplierAccountName,
            supplierBsb,
            supplierBankAccountNumber,
            fileGroupId,
            moment().toDate(),
            isNewSupplier,
            isDirector,
            disbursalMethod,
            disburseDate,
          )
          .then((response) => {
            formikBag.setSubmitting(false);

            if (response.error) {
              defaultHandleError({ enqueueSnackbar: formikBag.props.enqueueSnackbar }, null);
              return;
            }

            props.history.push({
              pathname: '/buyer/payments/review',
              state: { from: props.history.location.pathname, validRedirectFromBuyer: true },
            });
          });
      },
      displayName: 'AddInvoice',
    })(withWidth()(AddInvoice))
  )
);
