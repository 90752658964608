// @flow
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import { Button, FormField } from '@shiftfinancial/design-system';
import { withFormik, Form, Field } from 'formik';
import type { FormikBag } from 'formik';
import type { History, Location } from 'history';
import { withSnackbar } from 'notistack';
import React from 'react';
import { withRouter } from 'react-router';

import { formSchema } from './validations';
import { getFirstMessage } from '../../lib/apiHelpers';
import { formatMobileNumber } from '../../lib/formatter';
import gtmUtils from '../../lib/gtmUtils';
import FormikNumberFormatInputField from '../formik/FormikNumberFormatInputField';

const styles = (theme) => ({
  container: {
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(2, 2, 4),
      margin: '0 auto',
      maxWidth: '420px',
    },
    padding: theme.spacing(3, 2, 0),
  },
  label: {
    [theme.breakpoints.up('xs')]: {
      color: theme.palette.grey.light,
    },
    [theme.breakpoints.up('sm')]: {
      color: theme.palette.common.black,
    },
  },
  inputBase: {
    margin: theme.spacing(1, 0, 0),
  },
});

type FormValues = {
  pin: string,
};

type PinLoginFormProps = {
  inputMobileNumberValue: string,
  getPinByMobileNumber: (mobileNumber: string) => any,
  setError: (error: ?string) => void,
};

type WithHOCPinLoginFormProps = {
  ...PinLoginFormProps,
  classes: any,
  isSubmitting: boolean,
  values: FormValues,
  setFieldValue: (fieldName: string, value: any, shouldValidate?: boolean) => void,
};

const PinLoginForm = (props: WithHOCPinLoginFormProps) => {
  const { classes, isSubmitting, values, inputMobileNumberValue, getPinByMobileNumber, setError, setFieldValue } = props;

  const handleResendCode = () => {
    setError(null);
    setFieldValue('pin', '');

    getPinByMobileNumber(inputMobileNumberValue).then((response) => {
      if (response.error) {
        const { payload } = response;
        if (payload.response && payload.response.status === 400 && payload.response && payload.response.data && payload.response.data.messages) {
          const { messages } = payload.response.data;
          if (messages && Array.isArray(messages) && messages.length > 0) {
            const unregisteredMessage = messages.find((message) => message === "That phone number doesn't seem to be registered with us.");

            if (unregisteredMessage) {
              const formattedMobileNumber = formatMobileNumber(inputMobileNumberValue, true);
              const message =
                `The number ${formattedMobileNumber} is not registered with Shift Trade. ` +
                'If new to Shift Trade, click on "Register here" link below.';
              setError(message);
            } else {
              setError(messages[0]);
            }
          }
        }
      }
    });
  };
  return (
    <Grid className={classes.container} container>
      <Grid item xs={12}>
        <Form noValidate autoComplete='off'>
          <Grid item xs={12}>
            <FormField label='Enter verification code' fieldId='pin'>
              {({ fieldId }) => (
                <Field
                  component={FormikNumberFormatInputField}
                  variant='outlined'
                  fullWidth
                  id={fieldId}
                  name={fieldId}
                  pattern='[0-9]{6}'
                  format='######'
                  placeholder='Enter the 6-digit verification code'
                  InputProps={{
                    classes: {
                      root: classes.inputBase,
                    },
                  }}
                  autoFocus
                  data-testid='uia-pin'
                  value={values.pin}
                />
              )}
            </FormField>
          </Grid>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={6}>
              <Button appearance='primary' type='submit' fullWidth disabled={isSubmitting} data-testid='uia-login'>
                Log in
              </Button>
            </Grid>
            {inputMobileNumberValue ? (
              <Grid item xs={12} sm={6}>
                <Button
                  className={classes.button}
                  appearance='text'
                  type='button'
                  fullWidth
                  disabled={isSubmitting}
                  onClick={handleResendCode}
                  data-testid='uia-resend'
                >
                  Resend code
                </Button>
              </Grid>
            ) : null}
          </Grid>
        </Form>
      </Grid>
    </Grid>
  );
};

PinLoginForm.defaultProps = {
  inputMobileNumberValue: null,
  isSubmitting: false,
};

type PinLoginFormWrapperProps = {
  ...PinLoginFormProps,
  mobileNumber: string,
  getTokenByPin: (values: FormValues, mobileNumber: string) => Promise<any>,
  setLoginStep: (step: number) => void,
};

type WithHOCPinLoginFormWrapperProps = {
  ...PinLoginFormWrapperProps,
  history: History<Location>,
};

export default withRouter<PinLoginFormWrapperProps>(
  withSnackbar(
    withFormik({
      mapPropsToValues: (login): FormValues => ({
        pin: login.pin || '',
      }),
      validateOnBlur: false,
      validationSchema: formSchema,
      handleSubmit: (values: FormValues, formikBag: FormikBag<WithHOCPinLoginFormWrapperProps, FormValues>) => {
        const { props } = formikBag;
        props.setError(null);
        props.getTokenByPin(values, props.mobileNumber).then((response) => {
          formikBag.setSubmitting(false);

          if (response.error) {
            const { payload } = response;
            const message = getFirstMessage(payload);
            props.setError(message);
          } else if (response.payload && response.payload.status === 200) {
            gtmUtils.loginSuccessEvent(props.mobileNumber);
            props.setLoginStep(0);
            props.history.push('/');
          }
        });
      },
      displayName: 'PinLoginForm',
    })(withStyles(styles)(PinLoginForm))
  )
);
