import { type Dispatch } from 'redux';
import { createActions } from 'redux-actions';

import type { OrderBy } from '../../../api/shiftPaymentsApi';
import shiftPaymentsApi from '../../../api/shiftPaymentsApi';
import { getActionOptions } from '../../../lib/reduxActionsUtils';

const ACTION_PREFIX = 'SCHEDULED_INVOICES';

const actionTypes = {
  GET_SCHEDULED_INVOICES_PENDING: 'GET_SCHEDULED_INVOICES_PENDING',
  GET_SCHEDULED_INVOICES_FULFILLED: 'GET_SCHEDULED_INVOICES_FULFILLED',
  GET_SCHEDULED_INVOICES_REJECTED: 'GET_SCHEDULED_INVOICES_REJECTED',
  CANCEL_INVOICE_PENDING: 'CANCEL_INVOICE_PENDING',
  CANCEL_INVOICE_FULFILLED: 'CANCEL_INVOICE_FULFILLED',
  CANCEL_INVOICE_REJECTED: 'CANCEL_INVOICE_REJECTED'
};

const { getScheduledInvoicesPending, getScheduledInvoicesFulfilled, getScheduledInvoicesRejected, cancelInvoicePending, cancelInvoiceFulfilled, cancelInvoiceRejected } = createActions(
  actionTypes.GET_SCHEDULED_INVOICES_PENDING,
  actionTypes.GET_SCHEDULED_INVOICES_FULFILLED,
  actionTypes.GET_SCHEDULED_INVOICES_REJECTED,
  actionTypes.CANCEL_INVOICE_PENDING,
  actionTypes.CANCEL_INVOICE_FULFILLED,
  actionTypes.CANCEL_INVOICE_REJECTED,
  getActionOptions(ACTION_PREFIX)
);

const getScheduledInvoices = (gcAccountId: string, skip: number, limit: number, orderBy: OrderBy[] | null) => (dispatch: Dispatch<any>) => {
  dispatch(getScheduledInvoicesPending());

  return shiftPaymentsApi
    .getScheduledInvoices(gcAccountId, skip, limit, orderBy)
    .then((response) => {
      return dispatch(getScheduledInvoicesFulfilled(response));
    })
    .catch((error) => {
      return dispatch(getScheduledInvoicesRejected(error));
    });
};

const cancelInvoice = (invoiceId: string) => (dispatch: Dispatch<any>) => {
  dispatch(cancelInvoicePending());

  return shiftPaymentsApi
    .cancelInvoice(invoiceId)
    .then((response) => {
      return dispatch(cancelInvoiceFulfilled(response));
    })
    .catch((error) => {
      return dispatch(cancelInvoiceRejected(error));
    });
};

export default {
  getScheduledInvoices,
  cancelInvoice
};
